import axios from 'axios'


const DOMAIN = process.env.VUE_APP_API_HOST
const VALIDATE_BIZ_NUMBER_URL = '/api/v1/seller/validate/bizRegisterNumber'
const VALIDATE_ID_URL = '/auth/api/v1/accounts/member/validate/id'

export const Domain = axios.create({
    baseURL: DOMAIN,
    headers: {
        get: {
            "Content-Type": "*;charset=UTF-8"
        },
        post: {        // can be common or any other method
            "Content-Type": "text/plain;charset=UTF-8"
        },
        'Accept': 'application/json;charset=UTF-8'
    }
})

export const ValidateApi = {
    validateBizRegNumber(number){
        return Domain.post(VALIDATE_BIZ_NUMBER_URL,number,null).then( result =>{
            const res = result.data;
            console.log('성공')
            if( res.resCode === '0000'){
                console.log('요청 성공')
                console.log(result)
                console.log(res)
                return res.data
            }else{
                throw result.response
            }
        }).catch( result => {
            throw result.response
        })
    },
    validateId(id){
        return Domain.post(VALIDATE_ID_URL,id,null).then( result =>{
            const res = result.data;
            console.log('성공')
            if( res.resCode === '0000'){
                console.log('요청 성공')
                console.log(result)
                console.log(res)
                return res.data
            }else{
                throw result.response
            }
        }).catch( result => {
            throw result.response
        })
    },
    issueSmsAuth(number){
        return Domain.post(DOMAIN + '/api/v1/messages/public/sms/issue/authNumbers/signup',{
            phoneNumber:number
        },null).then( result =>{
            const res = result.data;
            if( res.resCode === '0000'){
                return res.data
            }else{
                throw result.response
            }
        }).catch( result => {
            throw result.response
        })
    },
    validateSmsAuthNumber(request){
        return Domain.post(DOMAIN + '/api/v1/messages/public/sms/authNumbers/signup/validate',request,null).then( result =>{
            const res = result.data;
            if( res.resCode === '0000'){
                return res.data
            }else{
                throw result.response
            }
        }).catch( result => {
            throw result.response
        })
    },
    authenticateNonMemberQuestion(request){
        return Domain.post(DOMAIN + '/wrss/api/v1/wspmain/nonmember/question/authenticate',request,null).then( result =>{
            const res = result.data;
            return res
        }).catch( result => {
            throw result.response
        })
    },
}